<template>
  <th-page-wrapper>
    <th-drawer ref="edit" v-model="isModalOpen" size="480px">
      <receipt-viewer
        v-if="currentRow.id"
        :key="currentRow.id"
        :receipts="currentRow.receipts"
        :receipt-title="currentRow.id"
        :resource-id="currentRow.id"
        @close-receipts="closeReceiptViewer"
        @cancel-requested="closeReceiptViewer"
      />
    </th-drawer>
    <th-modal name="datev-context" height="800px" width="600px">
      <datev-context v-bind="datevContextProps" @close="handleClose" />
    </th-modal>

    <th-datatable
      ref="table"
      do-route-filters
      expanding-row
      fuzzy-search
      headers-filterable
      no-meta-check
      prune-search-filters
      resource="balances"
      route-base="/reports/financial_accounting/balances"
      show-filter
      show-total-count
      sortable
      transform-fetched-meta-allowed
      :document-export="documentExport"
      :force-all-export-columns="true"
      export-document-type="BalancesExport"
      :header-name-to-export-column-map="headerNameToExportColumnMap"
      :export-filename-prefix="$t('pages.balances.exports.filename_prefix')"
      :handle-export="handleExport"
      :buttons="computedButtons"
      :custom-resource="balanceResource()"
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      :headers-config="headersConfig"
      :headers="headers"
      :locale="locale"
      :resource-limit="1000"
      :resource-query="resourceQuery"
      :search-filters="computedFiltersList"
      :show-operations="false"
      :summary-headers="summaryHeaders"
      :transform-fetched-data="transformFetchedData"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
    >
      <template #expanding-row="{ row }">
        <expanding-row
          :row="row"
          :is-legacy="isLegacy"
          :inherited-filter-values="expandingRowFilterValues"
          @open-current-receipts="handleOpenReceipts"
        />
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import qs from 'qs'
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import pick from 'just-pick'
import ExpandingRow from './components/expanding-row'
import ReceiptViewer from '../../../../components/tillhub/receipt-viewer'
import DatevContext from './components/datev-context'
import datatableHeadersConfig from '@/mixins/datatable-headers-config'
import { applyFiltersBeforeRouteEnter, getRangeFor } from '@/utils/date'
import { createLabel } from '@/utils/strings'
import mem from 'mem'
import { waitForData } from '@/utils/general'
import { useExportsStore } from '@/store/exports'

export default {
  name: 'ReportsFinancialAccountingBalances',
  metaInfo() {
    return {
      title: this.$t('pages.balances.title')
    }
  },
  components: {
    DatevContext,
    ExpandingRow,
    ReceiptViewer
  },
  beforeRouteEnter: (to, from, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, from, next) {
    // as UX enhancement we are going to persist some of the queries
    const elegibleObj = pick(safeGet(qs.parse(to.query), 'filter') || {}, [
      'date',
      'register',
      'register_custom_id',
      'register_number',
      'branch_group'
    ])
    this.$emit('route-filter', {
      ...elegibleObj,
      register_custom_id:
        elegibleObj.register_custom_id ||
        elegibleObj.register_number ||
        undefined,
      register_number:
        elegibleObj.register_number ||
        elegibleObj.register_custom_id ||
        undefined,
      register: elegibleObj.register || undefined
    })

    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.reports.financial_accounting.balances'
    )
    return {
      headersConfig,
      handleHeadersConfig
    }
  },
  data() {
    return {
      currentRow: {
        receipts: [],
        id: null
      },
      isModalOpen: false,
      datevContextProps: {},
      isLegacy: true,
      summaryHeaders: [
        {
          field: 'sum_amount_revenue_total',
          label: this.$t(
            'pages.balances.all.table.subtable.amount_revenue_total'
          ),
          fallback: '-',
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.sum_amount_revenue_total)) {
              return this.$formatCurrency(
                row.sum_amount_revenue_total,
                row.currency
              )
            }
            return '-'
          },
          align: 'right',
          sortType: 'currency'
        },
        {
          field: 'sum_amount_cash_calculated',
          label: this.$t('pages.balances.all.table.total_cash_calculated'),
          fallback: '-',
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.sum_amount_cash_calculated)) {
              return this.$formatCurrency(
                row.sum_amount_cash_calculated,
                row.currency
              )
            }
            return '-'
          },
          align: 'right',
          sortType: 'currency'
        }
      ],
      headers: [
        {
          field: '_date_label',
          label: this.$t('pages.transactions.all.table.date'),
          width: 190,
          truncate: true,
          sortType: 'date'
        },
        {
          field: 'custom_id',
          label: this.$t('pages.balances.all.table.balance_number'),
          minWidth: 160,
          truncate: true,
          sortType: 'number'
        },
        {
          field: '_staff_label',
          label: this.$t('pages.transactions.all.table.staff'),
          minWidth: 140,
          truncate: true
        },
        {
          field: 'branch_custom_id',
          label: this.$t('pages.transactions.all.table.branch_custom_id'),
          minWidth: 120,
          truncate: true,
          formatter: (row) =>
            createLabel([
              safeGet(row, 'branch_name'),
              safeGet(row, 'branch_custom_id')
            ]),
          sortType: 'number'
        },
        {
          field: 'register',
          label: this.$t('pages.transactions.all.table.register_custom_id'),
          minWidth: 120,
          truncate: true,
          formatter: (row) =>
            createLabel([
              safeGet(row, 'register_name'),
              safeGet(row, 'register_custom_id')
            ]),
          sortType: 'number'
        },
        {
          field: 'amount_revenue_total',
          label: this.$t(
            'pages.balances.all.table.subtable.amount_revenue_total'
          ),
          minWidth: 140,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.amount_revenue_total)) {
              return this.$formatCurrency(
                row.amount_revenue_total,
                row.currency
              )
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'amount_cash_calculated',
          label: this.$t('pages.balances.all.table.amount_cash_calculated'),
          minWidth: 140,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.amount_cash_calculated)) {
              return this.$formatCurrency(
                row.amount_cash_calculated,
                row.currency
              )
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'amount_cash_counted',
          label: this.$t('pages.balances.all.table.amount_cash_counted'),
          minWidth: 160,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.amount_cash_counted)) {
              return this.$formatCurrency(row.amount_cash_counted, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'cashless_amount',
          label: this.$t('pages.balances.all.table.cashless_amount'),
          minWidth: 160,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.cashless_amount)) {
              return this.$formatCurrency(row.cashless_amount, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'discrepancy',
          label: this.$t('pages.balances.all.table.discrepancy'),
          minWidth: 140,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.discrepancy)) {
              return this.$formatCurrency(row.discrepancy, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        }
      ],
      submitModalForm: () => {},
      hiddenFilters: [],
      additionalExportProps: {},
      headerNameToExportColumnMap: {
        _date_label: 'date',
        custom_id: 'custom_id',
        branch_custom_id: 'branch_custom_id',
        _staff_label: ['cashier_staff', 'cashier_staff_custom_id'],
        register: 'register_custom_id',
        amount_cash_counted: 'amount_cash_counted',
        amount_revenue_total: 'amount_revenue_total',
        discrepancy: 'discrepancy',
        cashless_amount: 'cashless_amount',
        amount_cash_calculated: 'amount_cash_calculated'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      branchNumber: 'Config/getCurrentBranchNumber',
      timeZone: 'Config/getTimeZone',
      locale: 'Config/getLocale',
      defaultDateSelected: 'Config/getDefaultDateSelected'
    }),
    buttons() {
      return [
        {
          label: this.$t('pages.balances.all.table.buttons.datev'),
          featureFlag: 'datev',
          svgicon: 'th-icon-download',
          clickHandler: ({ handleExport, handleDownload, resourceOptions }) => {
            this.showDatevModal((data) => {
              handleDownload(data.url, data.filename, undefined)
            })
          }
        }
      ]
    },
    documentExport() {
      return this.$checkPermissions({
        scopes: ['reports_financial_accounting:balances:export']
      })
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    parsedDate() {
      return this.parsedQuery.date || {}
    },
    date() {
      return {
        start: new Date(this.parsedDate.start),
        end: new Date(this.parsedDate.end),
        showDateText: true
      }
    },
    computedFiltersList() {
      return this.filtersList.filter(
        (f) => !this.hiddenFilters.includes(f.name)
      )
    },
    resourceQuery() {
      return {
        legacy: this.isLegacy,
        register_custom_id: this.parsedQuery.register_custom_id || undefined,
        register: this.parsedQuery.register || undefined,
        branch_custom_id: this.branchNumber,
        date_end: this.parsedDate.end || undefined,
        date_start: this.parsedDate.start || undefined,
        cashier_staff_custom_id:
          this.getStaff(this.parsedQuery.cashier_staff_custom_id)
            ?.staff_number || undefined
      }
    },
    computedButtons() {
      return (
        this.buttons
          // we're only allowing buttons that don't have explicit feature flags
          .filter((b) =>
            b.featureFlag ? this.$isFeatureEnabled(b.featureFlag) : true
          )
          .filter((b) =>
            b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
          )
      )
    },
    customResource() {
      return th.analytics().balances()
    },
    registers() {
      const { registers } = this.resources
      if (!registers) return
      return Array.from(registers || [])
        .filter(([, r]) => r.deleted !== true)
        .filter(
          ([, r]) => !this.currentLocation || r.branch === this.currentLocation
        )
        .filter(([, r]) => Number.isFinite(r.register_number)) // needed to prevent odd default behavior
        .map(([, r]) => ({
          value: r.id,
          label: this.$formatRegister(r),
          key: r.id
        }))
    },
    filtersList() {
      return [
        {
          name: 'register',
          type: 'select',
          label: this.$t('pages.transactions.all.table.register'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          options: this.registers,
          filterable: true
        },
        {
          name: 'cashier_staff_custom_id',
          type: 'remote-search-select',
          label: this.$t('pages.staff.all.headers.staff'),
          resource: 'staff',
          overrideInitialFetchHandler: 'get',
          fetchHandler: 'getAll',
          computeName: this.formatStaffLabel,
          modifyQuery: (q) => ({
            q,
            fields: ['staff_number', 'lastname', 'firstname'],
            deleted: false
          })
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'date',
          prop: ['start', 'end'],
          type: 'daterange',
          label: this.$t('pages.transactions.all.filters.date.label'),
          closable: false,
          noFutureDates: true,
          formatValue: (value) => this.$date.formatDateRange(value),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    expandingRowFilterValues() {
      return pick(safeGet(qs.parse(this.$route.query), 'filter') || {}, [
        'date'
      ])
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    showDatevModal(handleDownload) {
      this.datevContextProps = {
        handleDownload: handleDownload,
        parsedQuery: this.parsedQuery,
        resources: this.resources,
        timezone: this.timeZone
      }
      this.$thModal.show('datev-context')
    },
    updateAdditionalProps(newProps) {
      Object.assign(this.additionalExportProps, newProps)
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.balances.title')
        })
      })
    },
    getStaff: mem(function (staffId) {
      return this.resources?.staff?.get(staffId)
    }),
    getStaffLabel: mem(function (staffId) {
      const staff = this.getStaff(staffId)
      if (!staff) return ' – '
      return this.formatStaffLabel(staff)
    }),
    formatStaffLabel(staff) {
      return this.$formatStaff(staff, ['staff_number', 'fullName'], ' - ')
    },
    handleOpenReceipts(row) {
      this.currentRow.id = row.resourceId
      this.currentRow.receipts = row.receipts
      this.isModalOpen = true
    },
    closeReceiptViewer() {
      this.isModalOpen = false
    },
    balanceResource() {
      const inst = th.analyticsHandlers().analytics.reports
        .AnalyticsReportsBalancesOverview
      return inst
    },
    // NOTE: this is a perfomance optmisation in order not to parse in formatters, which seems to be costly in massive data scenarios.
    // The gist is: pre-digest strings, so the call stacks get thinner later. This mutates actual data inside the table
    async transformFetchedData(data) {
      await waitForData(() => this.resources?.staff)
      return data.map((item) => {
        const staff = this.resources.staff.get(item.cashier_staff)
        if (staff) {
          item._staff_label = this.$formatStaff(staff)
        }

        if (!item.date) {
          item._date_label = '-'
        } else {
          item._date_label = this.$date.formatDateTimeWithTimezone(item.date)
        }

        return item
      })
    },
    async handleExport(query) {
      try {
        const {
          data
        } = await th
          .analyticsHandlersV3()
          .analytics.reports.AnalyticsReportsBalances.export({ query })

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.balances.title',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV3',
              path: 'analytics.reports.AnalyticsReportsBalances',
              handler: 'export',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.balances.title')
          })
        })
      }
    }
  }
}
</script>

<style scoped>
.popover {
  margin-right: 10px;
}
</style>
