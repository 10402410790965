<template>
  <el-container v-loading="loading">
    <el-header>
      <h2 class="mb-0" v-text="$t('pages.balances.all.exports.datev.title')" />
    </el-header>
    <el-main>
      <p
        class="whitespace-pre-line mt-0"
        :class="{ italic: !isWhiteLabel }"
        v-text="$t('pages.balances.all.exports.datev.description')"
      />

      <div>
        <template v-if="!shouldHideBranchSelect">
          <th-input-title
            for="branch"
            :title="$t('pages.balances.all.exports.datev.branch.label')"
          />
          <remote-search-select
            id="branch"
            :model-value="currentBranch && currentBranch.id"
            :placeholder="$t('common.inputs.placeholders.select')"
            resource="branches"
            :computed-fields="['branch_number', 'name']"
            @resource-set="currentBranch = $event"
            @clear="currentBranch = {}"
          />
        </template>

        <!-- Branch -->
        <div v-if="currentRegister">
          <h3
            v-text="$t('pages.balances.all.exports.datev.current_register')"
          />

          <span class="input-holder" v-text="currentRegisterLabel" />
        </div>

        <div v-if="submitDisabled" class="pt-1 text-xs text-th-red">
          {{ submitDisabledReason }}
        </div>

        <hr class="my-4" />

        <el-row :gutter="20">
          <!-- Advisor -->
          <el-col :span="12" class="mb-2">
            <th-input-title
              :title="$t('pages.balances.all.exports.datev.advisor_number')"
            />
            <el-input v-model="advisor_number" disabled />
          </el-col>

          <!-- Client -->
          <el-col :span="12" class="mb-2">
            <th-input-title
              :title="$t(`pages.settings.accounting.datev.client_number.label`)"
            />
            <el-input v-model="client_number" disabled />
          </el-col>

          <!-- Start of Business Year -->
          <el-col :span="12" class="mb-2">
            <th-input-title
              :title="$t(`pages.settings.accounting.datev.business_year.label`)"
            />
            <el-input v-if="business_year" v-model="business_year" disabled />
            <el-input v-else value="-" disabled />
          </el-col>

          <!-- Account Number Length -->
          <el-col :span="12" class="mb-2">
            <th-input-title
              :title="
                $t(
                  `pages.settings.accounting.datev.account_number_length.label`
                )
              "
            />
            <el-input v-model="account_number_length" disabled />
          </el-col>

          <!-- SKR -->
          <el-col :span="12">
            <th-input-title
              :title="$t(`pages.settings.accounting.datev.skr.label`)"
            />
            <el-input v-model="skr" disabled />
          </el-col>
        </el-row>
      </div>
    </el-main>

    <el-footer class="flex justify-end items-center">
      <div class="mr-2">
        <el-select v-model="datevReportType" class="w-64">
          <el-option
            v-for="{ key, label, description } in datevReportTypes"
            :key="key"
            :value="key"
            :label="label"
            class="block max-w-md h-20"
          >
            <span class="font-bold leading-snug" v-text="label" />
            <p
              class="text-gray-800 font-normal whitespace-pre-wrap m-0 p-0 w-full leading-normal"
              v-text="description"
            />
          </el-option>
        </el-select>
      </div>

      <el-popover
        :disabled="!submitDisabled"
        placement="top-start"
        width="300"
        trigger="hover"
        :content="submitDisabledReason"
      >
        <template #reference>
          <div>
            <el-button
              type="primary"
              :disabled="submitDisabled"
              @click="handleExport"
            >
              {{ $t('common.interactions.buttons.export') }}
            </el-button>
          </div>
        </template>
      </el-popover>
    </el-footer>
  </el-container>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import isEmpty from 'just-is-empty'
import { mapGetters } from 'vuex'
import RemoteSearchSelect from '@/components/select/remote-search'
import { isUnifiedCommerce } from '@/constants'
import { useExportsStore } from '@/store/exports'

export default {
  components: {
    RemoteSearchSelect
  },
  props: {
    parsedQuery: {
      type: Object,
      required: true
    },
    resources: {
      type: Object,
      required: true
    },
    timezone: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data() {
    let currentBranch = null
    let currentRegister = null

    if (this.parsedQuery.branch) {
      currentBranch =
        (Array.from(this.resources.branches).find(([id, item]) => {
          return item.id === this.parsedQuery.branch
        }) || [])[1] || null
    } else if (this.parsedQuery.branch_custom_id) {
      currentBranch =
        (Array.from(this.resources.branches).find(([id, item]) => {
          return (
            String(item.branch_number) === this.parsedQuery.branch_custom_id
          )
        }) || [])[1] || null
    }

    if (this.parsedQuery.register) {
      currentRegister =
        (Array.from(this.resources.registers).find(([id, item]) => {
          if (currentBranch)
            return (
              item.branch === currentBranch.id &&
              item.id === this.parsedQuery.register
            )
          return item.id === this.parsedQuery.register
        }) || [])[1] || null
    } else if (this.parsedQuery.register_custom_id) {
      currentRegister =
        (Array.from(this.resources.registers).find(([id, item]) => {
          if (currentBranch)
            return (
              item.branch === currentBranch.id &&
              String(item.register_number) ===
                this.parsedQuery.register_custom_id
            )
          return (
            String(item.register_number) === this.parsedQuery.register_custom_id
          )
        }) || [])[1] || null
    }

    return {
      currentBranch,
      currentRegister,
      datevReportType: 'balances_closed_in_range',
      datevReportTypes: [
        {
          key: 'balances_closed_in_range',
          label: this.$t(
            'pages.balances.all.exports.datev.type.balances_closed_in_range.title'
          ),
          description: this.$t(
            'pages.balances.all.exports.datev.type.balances_closed_in_range.description'
          )
        },
        {
          key: 'balances_started_in_range',
          label: this.$t(
            'pages.balances.all.exports.datev.type.balances_started_in_range.title'
          ),
          description: this.$t(
            'pages.balances.all.exports.datev.type.balances_started_in_range.description'
          )
        },
        {
          key: 'balances_active_in_range',
          label: this.$t(
            'pages.balances.all.exports.datev.type.balances_active_in_range.title'
          ),
          description: this.$t(
            'pages.balances.all.exports.datev.type.balances_active_in_range.description'
          )
        },
        {
          key: 'balances_inside_range',
          label: this.$t(
            'pages.balances.all.exports.datev.type.balances_inside_range.title'
          ),
          description: this.$t(
            'pages.balances.all.exports.datev.type.balances_inside_range.description'
          )
        }
      ],
      loading: false,
      configurations: []
    }
  },
  computed: {
    ...mapGetters({
      currentBranchNumber: 'Config/getCurrentBranchNumber',
      currentLocation: 'Config/getCurrentLocation',
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    isMultiTenantMode() {
      return (
        safeGet(this.clientAccountConfiguration, 'metadata.fiskaly_mode') ===
        'multitenant'
      )
    },
    multipleBranchesDatevConfig() {
      const isDatevConfigNotEmpty = ({ datev }) => !isEmpty(datev)

      const isMultipleConfig = (config) =>
        config?.filter(isDatevConfigNotEmpty).length > 1 ?? false

      return this.isMultiTenantMode && isMultipleConfig(this.configurations)
    },
    advisor_number() {
      return safeGet(this.clientAccountConfiguration, 'datev.Berater', null)
    },
    client_number() {
      const branchConfiguration = this.configurations.find(
        ({ owner }) => owner === this.currentLocation
      )
      return (
        safeGet(branchConfiguration, 'datev.Mandant', null) ||
        safeGet(this.clientAccountConfiguration, 'datev.Mandant', null)
      )
    },
    business_year() {
      return safeGet(this.clientAccountConfiguration, 'datev.WJ-Beginn', null)
    },
    account_number_length() {
      return safeGet(
        this.clientAccountConfiguration,
        'datev.Sachkonten-nummernlänge',
        null
      )
    },
    skr() {
      return safeGet(this.clientAccountConfiguration, 'datev.SKR', null)
    },
    isSingleBranchAccount() {
      const branches = Array.from(this.resources?.branches ?? [])
        .map(([id, branch]) => branch)
        .filter(({ deleted }) => !deleted)
      return branches?.length === 1
    },
    shouldHideBranchSelect() {
      return this.isSingleBranchAccount || !!this.currentBranchNumber
    },
    currentBranchCustomId() {
      return !this.isSingleBranchAccount
        ? this.currentBranchNumber ??
            safeGet(this.currentBranch, 'branch_number')
        : undefined
    },
    currentRegisterCustomId() {
      return safeGet(this.currentRegister, 'register_number')
    },
    currentBranchLabel() {
      return this.$formatBranch(this.currentBranch)
    },
    currentRegisterLabel() {
      return this.$formatRegister(this.currentRegister)
    },
    submitDisabledNoDate() {
      return (
        !safeGet(this.parsedQuery, 'date.start') ||
        !safeGet(this.parsedQuery, 'date.end')
      )
    },
    submitDisabledNoBranch() {
      return this.multipleBranchesDatevConfig && !this.currentBranchCustomId
    },
    submitDisabled() {
      return this.submitDisabledNoDate || this.submitDisabledNoBranch
    },
    submitDisabledReason() {
      if (this.submitDisabledNoDate) {
        return this.$t(
          'pages.balances.all.exports.buttons.submit.disabled.no_date.description'
        )
      }

      if (this.submitDisabledNoBranch) {
        return this.$t(
          'pages.balances.all.exports.buttons.submit.disabled.no_branch.description'
        )
      }

      return undefined
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  mounted() {
    this.fetchResources()
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    async handleExport() {
      const query = {
        branch_custom_id: this.currentBranchCustomId ?? undefined,
        register_custom_id: this.currentRegisterCustomId ?? undefined,
        date_start: safeGet(this.parsedQuery, 'date.start'),
        date_end: safeGet(this.parsedQuery, 'date.end'),
        timezone: this.timezone,
        type: this.datevReportType || 'balances_closed_in_range'
      }

      try {
        const inst = th.analyticsHandlersV3().analytics.reports
          .AnalyticsReportsDatev
        const { data } = await inst.export(query)
        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }
        this.closeDialog()

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.balances.all.exports.datev.title',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV3',
              path: 'analytics.reports.AnalyticsReportsDatev',
              handler: 'export',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.balances.all.exports.datev.title')
          })
        })
      }
    },
    async fetchResources() {
      this.loading = true
      try {
        const { configurations } = await this.$resourceFetch('configurations')
        this.configurations = configurations
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
