<template>
  <el-row v-loading="loading" class="expanding-row">
    <el-col :span="8">
      <!-- Overview -->
      <div>
        <div
          class="label-section"
          v-text="$t('pages.balances.all.table.subtable.label.overview')"
        />
        <el-row v-for="{ key, label } in summary" :key="key">
          <el-col :span="12" class="body-section">
            <span v-text="label" />
          </el-col>
          <el-col :span="12" class="currency-section">
            <span v-text="getBalanceItem(key)" />
          </el-col>
        </el-row>
      </div>

      <!-- Payment Options -->
      <div class="sub-table-row">
        <div
          class="label-section"
          v-text="$t('pages.balances.all.table.subtable.label.payment_option')"
        />
        <el-row v-for="payment in payments" :key="payment.name">
          <el-col :span="12" class="body-section">
            <span v-text="payment.name" />
          </el-col>
          <el-col :span="12" class="currency-section">
            <span v-text="formatCurrency(payment.sum)" />
          </el-col>
        </el-row>
      </div>

      <!-- Cash Discrepancy -->
      <div class="sub-table-row">
        <div
          class="label-section"
          v-text="
            $t('pages.balances.all.table.subtable.label.cash_discrepancy')
          "
        />
        <el-row v-for="{ key, label } in cashDiscrepancy" :key="key">
          <el-col :span="12" class="body-section">
            <span v-text="label" />
          </el-col>
          <el-col :span="12" class="currency-section">
            <span v-text="getBalanceItem(key)" />
          </el-col>
        </el-row>
      </div>

      <!-- Account Proceeds -->
      <div class="sub-table-row">
        <div
          class="label-section"
          v-text="$t('pages.balances.all.table.subtable.label.accounts')"
        />
        <el-row v-for="revenue in revenues" :key="revenue.account">
          <el-col :span="12" class="body-section">
            <span v-text="revenue.account" />
          </el-col>
          <el-col :span="12" class="currency-section">
            <span v-text="formatCurrency(revenue.sum)" />
          </el-col>
        </el-row>
      </div>

      <!-- VAT accounts -->
      <div class="sub-table-row">
        <div
          class="label-section"
          v-text="$t('pages.balances.all.table.subtable.label.vat')"
        />
        <el-row v-for="vat in taxes" :key="vat.account">
          <el-col :span="12" class="body-section">
            <span v-text="vat.account" />
          </el-col>
          <el-col :span="12" class="currency-section">
            <span v-text="formatCurrency(vat.sum)" />
          </el-col>
        </el-row>
      </div>
    </el-col>

    <!-- Actions -->
    <el-col :span="16" class="actions-column">
      <router-link :to="associatedLink">
        <el-button text class="button-mini" icon="Link">
          {{
            $t('pages.transactions.all.table.subtable.actions.show_associated')
          }}
        </el-button>
      </router-link>
      <el-button
        text
        class="button-mini"
        :disabled="!getReceipts || !getReceipts.length"
        @click="openReceiptViewer"
        v-text="
          $t('pages.transactions.all.table.subtable.actions.show_receipt')
        "
      />
      <el-button
        text
        class="button-mini"
        disabled
        v-text="
          $t('pages.transactions.all.table.subtable.actions.print_receipt')
        "
      />
      <el-button
        text
        class="button-mini"
        disabled
        v-text="
          $t('pages.transactions.all.table.subtable.actions.print_quittance')
        "
      />
    </el-col>
  </el-row>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import qs from 'qs'

export default {
  props: {
    row: {
      type: Object,
      default: undefined
    },
    isLegacy: {
      type: Boolean,
      required: true
    },
    inheritedFilterValues: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      receiptViewerOpen: false,
      query: {
        balanceId: this.row.balance_id,
        query: {
          currency: this.row.currency,
          legacy: this.isLegacy
        }
      },
      balance: {},
      cart: {},
      summary: [
        {
          key: 'amount_revenue_total',
          label: this.$t(
            'pages.balances.all.table.subtable.amount_revenue_total'
          )
        },
        {
          key: 'amount_cash_begin',
          label: this.$t('pages.balances.all.table.subtable.amount_cash_begin')
        },
        {
          key: 'amount_revenue_cash',
          label: this.$t(
            'pages.balances.all.table.subtable.amount_revenue_cash'
          )
        },
        {
          key: 'amount_expenses',
          label: this.$t('pages.balances.all.table.subtable.amount_expenses')
        }
      ],
      cashDiscrepancy: [
        {
          key: 'amount_cash_calculated',
          label: this.$t(
            'pages.balances.all.table.subtable.amount_cash_calculated'
          )
        },
        {
          key: 'amount_cash_counted',
          label: this.$t(
            'pages.balances.all.table.subtable.amount_cash_counted'
          )
        },
        {
          key: 'cash_discrepancy',
          label: this.$t('pages.balances.all.table.subtable.cash_discrepancy')
        }
      ]
    }
  },
  computed: {
    locale() {
      return this.$store.getters['Config/getLocale']
    },
    getReceipts() {
      return safeGet(this.balance, 'receipts') || []
    },
    associatedLink() {
      const query = qs.stringify({
        filter: {
          ...this.inheritedFilterValues,
          balance_custom_id: this.row.custom_id || undefined,
          register_custom_id: this.row.register_custom_id || undefined,
          branch: this.row.branch || undefined
        }
      })

      return `transactions?${query}`
    },
    payments() {
      return this.balance.payments || []
    },
    revenues() {
      return this.balance.revenues || []
    },
    taxes() {
      return this.balance.taxes || []
    }
  },
  created() {
    this.$emitter.on('close-receipts', this.closeDialog)
  },
  mounted() {
    // make this super deferred for perf
    this.$nextTick(() => {
      this.fetch()
    })
  },
  methods: {
    openReceiptViewer() {
      this.$emit('open-current-receipts', {
        receipts: this.getReceipts,
        receiptTitle: this.row.id,
        resourceId: this.row.id
      })
    },
    getBalanceItem(name) {
      const data = safeGet(this.balance, `${name}`)
      if (data === undefined) return '--'

      return this.formatCurrency(data)
    },
    formatCurrency(data) {
      return this.$formatCurrency(data, safeGet(this.balance, 'currency'))
    },
    async fetch() {
      const errMessage = this.$t(
        'pages.balances.all.table.subtable.errors.fetch5xx.message'
      )
      try {
        this.loading = true
        const inst = th.analyticsHandlers().analytics.reports
          .AnalyticsReportsBalancesDetail
        const { data } = await inst.get(this.row.id)
        this.balance = data
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: errMessage
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.sub-table-row {
  border-top: 1px solid var(--border-color);
  padding-top: 5px;
}

.mg-bottom-sm {
  margin-bottom: 7px;
}

.actions-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.label-section {
  margin-bottom: 5px;
  font-weight: bold;
}

.body-section {
  margin-bottom: 5px;
  font-weight: normal;
}

.currency-section {
  margin-bottom: 5px;
  text-align: right;
  font-weight: bold;
}
</style>
